import React from 'react'
import './style.scss'

import { StaticQuery, graphql } from 'gatsby'

const CallToAction = ({ data }) => {
  return (
    <section>
      <div className="container pt4">
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div
            key={node.id}
            className="bordered p-5 m-6 bg-light"
            data-aos="fade-up"
          >
            <div className="row justify-content-between align-items-center text-center text-md-left">
              <div className="col-md-2">
                <i className="svg-icon fs-60 text-pink">
                  <svg
                    version="1.1"
                    id="Layer_1"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="64px"
                    height="64px"
                    viewBox="0 0 64 64"
                    enableBackground="new 0 0 64 64"
                    xmlSpace="preserve"
                  >
                    <path
                      fill="none"
                      stroke="#000000"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      d="M11,48C5.477,48,1,43.523,1,38s4.477-10,10-10h2v20
                H11z"
                    />
                    <path
                      fill="none"
                      stroke="#000000"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      d="M53,28c5.523,0,10,4.477,10,10s-4.477,10-10,10h-2
                V28H53z"
                    />
                    <path
                      fill="none"
                      stroke="#000000"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      d="M13,31v-9c0,0,0-16,19-16s19,16,19,16v6"
                    />
                    <polyline
                      fill="none"
                      stroke="#000000"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                      points="51,48 51,53 36,59 28,59 28,55 36,55
                36,58 "
                    />
                  </svg>
                </i>
              </div>

              <div className="col-md-6">
                <h3>{node.frontmatter.title}</h3>
                <p>{node.frontmatter.sub}</p>
              </div>
              <div className="col-md-4 text-lg-right">
                <a
                  href="/contact"
                  className="btn btn-pink btn-rounded px-5 mt-2 mt-md-0"
                >
                  {node.frontmatter.button}
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        allMarkdownRemark(
          filter: { frontmatter: { category: { eq: "cta" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                category
                title
                sub
                button
              }
            }
          }
        }
      }
    `}
    render={data => <CallToAction data={data} {...props} />}
  />
)
