import React from 'react'
import ServiceHeader from './serviceHeader'
import Service from './service'

import './style.scss'

const Services = () => (
  <section id="services" className=" pb-6">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <ServiceHeader />
          <div className="row gutter-2">
            <Service />
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Services
