import React from 'react'
import Layout from '../components/Layout/index'
import Services from '../components/Services/index'
import CTA from '../components/CallToAction/index'
import LayoutWhite from 'components/LayoutWhite/index'

export default function schedule() {
  return (
    <LayoutWhite>
      <Services />
      <CTA />
    </LayoutWhite>
  )
}
