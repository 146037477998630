import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
import PortableText from '../portableText'
import './style.scss'

export default function Service() {
  const data = useStaticQuery(graphql`
    fragment SanityImage on SanityMainImage {
      crop {
        _key
        _type
        top
        bottom
        left
        right
      }
      hotspot {
        _key
        _type
        x
        y
        height
        width
      }
      asset {
        _id
      }
    }
    {
      allSanityService(sort: { order: ASC, fields: priority }) {
        edges {
          node {
            title
            priority
            mainImage {
              ...SanityImage
              alt
            }
            _rawBody(resolveReferences: { maxDepth: 5 })
          }
        }
      }
    }
  `)
  const allSanityService = (data || {}).allSanityService

  return (
    <div>
      {allSanityService.edges
        .filter((service) => service.node.priority !== null)
        .map(({ node }) => (
          <div class="card mb-3">
            <div class="row no-gutters">
              <div class="col-md-8">
                <div class="card-body">
                  <h5 class="card-title">{node.title}</h5>
                  <p class="card-text">
                    <PortableText blocks={node._rawBody} />
                  </p>
                </div>
              </div>
              <div class="col-md-4 imageFlex">
                <img
                  src={imageUrlFor(buildImageObj(node.mainImage))
                    .width(1200)
                    //.height(Math.floor((9 / 16) * 1200))
                    //.fit('crop')
                    .auto('format')
                    .url()}
                  alt={node.mainImage.alt}
                />
              </div>
            </div>
          </div>
        ))}
    </div>
  )
}
