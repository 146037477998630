import React from 'react'
import './style.scss'

const ServiceHeader = () => {
  return (
    <div className="row pt-5" data-aos="fade-up">
      <div className="col-md-12">
        <h1 className="text-pink text-center">Classes</h1>
        <p className="lead text-gray">
          Come and join me for an energising yoga practise to build strength &
          well-being, increase flexibility and reduce stress.
        </p>
        <ul className=" lead text-gray">
          <li>Wear something comfortable</li>
          <li>Feel free to bring water to the class</li>
          <li>
            To make the most of your practice do not eat up to 2 hours before
          </li>
          <li>Listen to your body and work at your own pace</li>
          <li>Remember to breathe</li>
          <li>Come with an open mind, Smile and enjoy!</li>
        </ul>
        <p className="lead text-gray">
          121 Yoga
          <br />
          If you are interested in 121 yoga classes I am happy to offer private
          classes in your home. Feel free to contact me.
        </p>
      </div>
    </div>
  )
}

export default ServiceHeader
